<template>
  <div class="thumbnails">
    <div class="banner thumbnails__imgWrap">
      <img class="banner__img thumbnails__img" src="../assets/img/banners/reject.jpg" alt="">
    </div>
    <div class="thumbnails__textWrap">
      <h1 class="titlePage">{{reason.title}}</h1>
      <p class="text">{{reason.name}}</p>
      <p class="text text_default" v-for="(item, index) in reason.text" :key="index">{{item}}</p>
      <p class="text text_italic text_green">If you have any further questions and want to further discuss your
        application, please don’t hesitate to contact Starline Security on 07 3272 2974.</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "RejectStatus",

    computed:{
      reason(){
        return this.$store.getters['status/reject'];
      },
    },
  }
</script>

<style scoped>

</style>
